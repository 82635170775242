import _ from 'underscore';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayArray, displayValues, fuelleSelectOptionen } from '../util.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereAppEinstellungen = speichereAppEinstellungen;
window.myHandlers.speichereCronjobEinstellungen = speichereCronjobEinstellungen;
window.myHandlers.speichereUnternehmenStandards = speichereUnternehmenStandards;
window.myHandlers.clickNeuesVerzeichnis = clickNeuesVerzeichnis;
window.myHandlers.confirmNeuesVerzeichnisDialog = confirmNeuesVerzeichnisDialog;
window.myHandlers.speicherePostfachVerzeichnisse = speicherePostfachVerzeichnisse;
window.myHandlers.clickEntferneVerzeichnis = clickEntferneVerzeichnis;
window.myHandlers.freigabeVerzeichnis = freigabeVerzeichnis;
window.myHandlers.changeColor = changeColor;
window.myHandlers.useDesign = useDesign;
window.myHandlers.updateDesign = updateDesign;

/**
 * lädt/speichert die Einstellungen der Apps
 */
async function ladeAppEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-app');
	displayValues(unternehmen.MitarbeiterAppEinstellungen, '', panelEinstellungen, 'u-');
	loadColorSettings();
}

async function speichereAppEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-app');
	collectValues(unternehmen.MitarbeiterAppEinstellungen, 'u-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}

async function ladeCronjobEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-cronjob');
	displayValues(unternehmen.NeolohnEinstellungen, '', panelEinstellungen, 'u-Cronjob-');
}

async function speichereCronjobEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-cronjob');
	collectValues(unternehmen.NeolohnEinstellungen, 'u-Cronjob-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Funktionen/Event Handlers für die Verzeichnisverwaltung
 */
async function ladePostfachVerzeichnisse() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-verzeichnisse');
	// Wir zeigen hier nur die zusätzlichen Verzeichnisse an
	displayArray(unternehmen.Verzeichnisse.filter((vz) => vz.SystemVerzeichnis && vz.VerzeichnisName !== 'Papierkorb'), 'u-system-verzeichnis-', panelEinstellungen);
	// Wir zeigen hier nur die zusätzlichen Verzeichnisse an
	displayArray(unternehmen.Verzeichnisse.filter((vz) => !vz.SystemVerzeichnis), 'u-zusatz-verzeichnis-', panelEinstellungen);
}

async function speicherePostfachVerzeichnisse() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-verzeichnisse');
	// Verzeichnisse speichern
	unternehmen.Verzeichnisse.forEach((verzeichnis) => {
		if (!verzeichnis.SystemVerzeichnis) {
			// wir suchen die VerzeichnisID im Frontend und die zugehörigen Inputfelder.
			const zeile = panelEinstellungen.querySelector(`[aria-label="${verzeichnis._id}"]`);
			const zeileVerzeichnisName = zeile.querySelector('[aria-label="u-zusatz-verzeichnis-VerzeichnisName"]').value;
			// einen leeren String wollen wir nicht als Namen haben
			verzeichnis.VerzeichnisName = zeileVerzeichnisName === '' ? verzeichnis.VerzeichnisName : zeileVerzeichnisName;
		}
	});
	updateDebounceUnternehmen(unternehmen);
}

async function clickNeuesVerzeichnis() {
	const input = await SystemDialogService.instance.displayAsync('neu-verzeichnis-dialog');
	if (input.success) {
		const result = await stammdatenService.erstellePostfachVerzeichnis(input.data);
		if (result) {
			await ladePostfachVerzeichnisse();
		}
	}
}

async function confirmNeuesVerzeichnisDialog() {
	const verzeichnisName = document.getElementById('neu-verzeichnis-name').value;
	SystemDialogService.instance.confirm(verzeichnisName);
}

async function clickEntferneVerzeichnis(thisElement) {
	const verzeichnisID = thisElement.parentNode.getAttribute('aria-label');
	const result = await stammdatenService.entfernePostfachVerzeichnis(verzeichnisID);
	if (result) {
		await ladePostfachVerzeichnisse();
	}
}

async function ladeMitarbeiterStandards() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-standard');
	const select = panelEinstellungen.querySelector('[aria-label="u-standard-PausenmodellStandard"]');
	fuelleSelectOptionen(select, unternehmen.Pausenmodelle, '_id', 'Bezeichnung', true, 'keins');
	displayValues(
		_.pick(unternehmen, [
			'SFNKappen',
			'SFNMindestlohnrelevant',
			'SFNAusgleichsbetragSVpflichtig',
			'N6ZuschlagZusatz',
			'GutzeitAutomatisierung',
			'AutomatischeProbezeitBis',
			'AutomatischeBefristungBis',
			'PausenmodellStandard',
			'UrlaubAmFeiertag', 
			'ZeiterfassungNurMitDienstplan'
		]),
		'',
		panelEinstellungen,
		'u-standard-'
	);
}

async function speichereUnternehmenStandards() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-standard');
	collectValues(unternehmen, 'u-standard-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}
async function ladeAlleEinstellungen() {
	await ladeAppEinstellungen();
	await ladeCronjobEinstellungen();
	await ladePostfachVerzeichnisse();
	await ladeMitarbeiterStandards();
}

/**
 * Sendet die Anfrage an des Server, das ausgewählte Verzeichnis freizugeben (oder die Freigabe zu widerrufen).
 * - Gibt alle im Verzeichnis unterliegenden Dateien frei, bzw. nicht mehr frei
 * @param {HTMLElement} thisElement input[type=checkbox]
 */
async function freigabeVerzeichnis(thisElement) {
    const verzeichnisID = _.last(thisElement.closest('.u-verzeichnis-zeile').id.split('-'));
    try {
        const response = await fetch('/neolohn/api/verzeichnis/freigeben', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                VerzeichnisID: verzeichnisID,
            })
        });
        if (!response.ok) {
			const msg = await response.text();
			systemNachrichtService.zeigeKleineNachricht(msg);
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		systemNachrichtService.zeigeKleineNachricht('Verzeichnisfreigabe aktualisiert!', 1);
		await stammdatenService.holeUnternehmensdaten(true);
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Aktualisierung fehlgeschlagen!', -1);
    }
}

function loadColorSettings() {
	const primaryColor = localStorage.getItem('primaryColor');
	const secondaryColor = localStorage.getItem('secondaryColor');
	const thirdColor = localStorage.getItem('thirdColor');
	const fieldColor = localStorage.getItem('fieldColor');
	const field2Color = localStorage.getItem('field2Color');
	const root = document.querySelector(':root');
	if (primaryColor) root.style.setProperty('--primary-color', primaryColor);
	else localStorage.setItem('primaryColor', '#00548f');
	if (secondaryColor) root.style.setProperty('--secondary-color', secondaryColor);
	else localStorage.setItem('secondaryColor', '#0071bd');
	if (thirdColor) root.style.setProperty('--third-color', thirdColor);
	else localStorage.setItem('thirdColor', '#d9eaf5');
	if (fieldColor) root.style.setProperty('--background-grey', fieldColor);
	else localStorage.setItem('fieldColor', '#ececec');
	if (field2Color) root.style.setProperty('--background-grey2', field2Color);
	else localStorage.setItem('field2Color', '#a3acbd');
	document.getElementById('color-primary').value = localStorage.getItem('primaryColor');
	document.getElementById('color-secondary').value = localStorage.getItem('secondaryColor');
	document.getElementById('color-third').value = localStorage.getItem('thirdColor');
	document.getElementById('color-field').value = localStorage.getItem('fieldColor');
	document.getElementById('color-field2').value = localStorage.getItem('field2Color');
}


function changeColor(thisElement, colorNumber) {
	const newColor = thisElement.value;
	const root = document.querySelector(':root');
	switch (colorNumber) {
		case 1:
			root.style.setProperty('--primary-color', newColor);
			localStorage.setItem('primaryColor', newColor);
			break;
		case 2:
			root.style.setProperty('--secondary-color', newColor);
			localStorage.setItem('secondaryColor', newColor);
			break;
		case 3:
			root.style.setProperty('--third-color', newColor);
			localStorage.setItem('thirdColor', newColor);
			break;
		case 4:
			root.style.setProperty('--background-grey', newColor);
			localStorage.setItem('fieldColor', newColor);
			break;
		case 5:
			root.style.setProperty('--background-grey2', newColor);
			localStorage.setItem('field2Color', newColor);
			break;
		default:
			break;
	}
}

function useDesign(designNumber) {
	const standard = ['#00548f', '#0071bd', '#d9eaf5', '#ececec', '#a3acbd'].join('::');
	let designString = designNumber == 0 ? standard : localStorage.getItem(`design${designNumber}`);
	const design = designString.split('::');
	const primaryColor = design[0];
	document.getElementById('color-primary').value = primaryColor;
	const secondaryColor = design[1];
	document.getElementById('color-secondary').value = secondaryColor;
	const thirdColor = design[2];
	document.getElementById('color-third').value = thirdColor;
	const fieldColor = design[3];
	document.getElementById('color-field').value = fieldColor;
	const field2Color = design[4];
	document.getElementById('color-field2').value = field2Color;
	const root = document.querySelector(':root');
	if (primaryColor) { 
		root.style.setProperty('--primary-color', primaryColor);
		localStorage.setItem('primaryColor', primaryColor);
	}
	if (secondaryColor) {
		root.style.setProperty('--secondary-color', secondaryColor);
		localStorage.setItem('secondaryColor', secondaryColor);
	}
	if (thirdColor) {
		root.style.setProperty('--third-color', thirdColor);
		localStorage.setItem('thirdColor', thirdColor);
	}
	if (fieldColor) {
		root.style.setProperty('--background-grey', fieldColor);
		localStorage.setItem('fieldColor', fieldColor);
	}
	if (field2Color) {
		root.style.setProperty('--background-grey2', field2Color);
		localStorage.setItem('field2Color', field2Color);
	}
	
}

function updateDesign(designNumber) {
	const primaryColor = document.getElementById('color-primary').value;
	const secondaryColor = document.getElementById('color-secondary').value;
	const thirdColor = document.getElementById('color-third').value;
	const fieldColor = document.getElementById('color-field').value;
	const field2Color = document.getElementById('color-field2').value;
	localStorage.setItem(`design${designNumber}`, [primaryColor, secondaryColor, thirdColor, fieldColor, field2Color].join('::'));
}

export {
    ladeAlleEinstellungen
};
