/**
 * Hier kommt Code für die Mitarbeiter Übersicht hin
 */

import _  from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';
import stammdatenService from '../services/stammdatenService.js';
import { debounce, displayValues, erstelleMitarbeiterListe, fuelleSelectOptionen } from '../util.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import basedataService from '../services/basedataService.js';
import { debounceSpeichereMA } from './ma_daten.js';
import { Roles } from '../../../../../domain/konstanten/Roles.js';

const debounceUpdateNachrichtMA = debounce(async (args) => {
    await speichereMitarbeiterNachricht(args[0]);
});

window.myHandlers = window.myHandlers || {};
window.myHandlers.debounceUpdateNachrichtMA = debounceUpdateNachrichtMA;
window.myHandlers.neueMitarbeiterNachricht = neueMitarbeiterNachricht;
window.myHandlers.clickZurBenutzerverwaltung = clickZurBenutzerverwaltung;
window.myHandlers.generiereAktivierungsCode = generiereAktivierungsCode;
window.myHandlers.beantrageSofortmeldung = beantrageSofortmeldung;
window.myHandlers.storniereSofortmeldung = storniereSofortmeldung;
window.myHandlers.reaktiviereMitarbeiterLoginClick = reaktiviereMitarbeiterLoginClick;
window.myHandlers.deaktiviereMitarbeiterLoginClick = deaktiviereMitarbeiterLoginClick;
window.myHandlers.reaktiviereMitarbeiterClick = reaktiviereMitarbeiterClick;
window.myHandlers.archiviereMitarbeiterClick = archiviereMitarbeiterClick;
window.myHandlers.wechsleBeschaeftigungMA = wechsleBeschaeftigungMA;
window.myHandlers.confirmWechselBeschaeftigungMA = confirmWechselBeschaeftigungMA;
window.myHandlers.speichereMitarbeiterNotiz = speichereMitarbeiterNotiz;

/**
 * Bestückt die Mitarbeiter Übersicht mit Daten des aktuellen Mitarbeiters
 * @param {object} aktuellerMA
 * @param {object} unternehmen
 */
async function ladeUebersicht(aktuellerMA, unternehmen) {
    if (!_.isEmpty(aktuellerMA)) {
        // Mitarbeiter Notizen und Nachrichten laden...
        document.getElementById('ma-notizen').value = stammdatenService.aktuellerMitarbeiter.Uebersicht.Notizen;
        ladeMitarbeiterNachrichten(aktuellerMA.Uebersicht.Nachrichten);
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const htmlParent = document.getElementById('ma-Uebersicht');
        htmlParent.querySelector('#ma-Kurzarbeit-Tage').style.display = aktuelleBeschaeftigung.Personengruppenschluessel === '110' ? 'grid' : 'none';
        displayValues(aktuellerMA.Uebersicht, '', htmlParent, 'ma-');
        displayValues(aktuelleBeschaeftigung, '', htmlParent, 'ma-');
        displayValues(aktuellerMA.Personalien, '', htmlParent, 'ma-');
        // Qualifikationen anzeigen
        const qualiHTML = htmlParent.querySelector('[aria-label="ma-Qualifikation"]');
        qualiHTML.innerText = '';
        aktuelleBeschaeftigung.Verguetung.forEach((quali, index) => {
            const qualifikation = unternehmen.Qualifikationen.find((q) => q._id === quali.QualifikationID);
            qualiHTML.innerText += `${index === 0 ? '' : ', '}${qualifikation?.BezeichnungNeutral}`;
        });
        // Zugeordnete Posten anzeigen
        const postenHTML = htmlParent.querySelector('[aria-label="ma-Posten"]');
        postenHTML.innerText = '';
        let postenMatchCounter = 0;
        // Wähle aktuelle Betriebsstätte des Mitarbeiters
        const aktuelleBS = unternehmen.Betriebsstaette.find((bs) => bs._id === aktuelleBeschaeftigung.BetriebsstaetteID);
        htmlParent.querySelector('[aria-label="ma-BetriebsstaetteName"]').innerText = aktuelleBS.BetriebsstaetteName;
        aktuelleBeschaeftigung.Posten.forEach((posten) => {
            const postenCounter = aktuelleBS.Posten.find((b) => b._id === posten.PostenID);
            if (postenCounter !== undefined) {
                postenHTML.innerText += `${postenMatchCounter === 0 ? '' : ', '}${postenCounter.Posten}`;
                postenMatchCounter += 1;
            }
        });
        if (postenMatchCounter === 0) {
            postenHTML.innerText = 'keine Posten';
        }
        // Regelarbeitstage anzeigen (abhängig von den eingegebenen Regelarbeitszeiten in der Beschäftigung)
        const tage = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
        const regeltage = htmlParent.querySelector('[aria-label="ma-Regelarbeitstage"]');
        regeltage.innerText = '';
        aktuelleBeschaeftigung.Regelarbeitstage.forEach((tag, index) => {
            if (tag) {
                regeltage.innerText += `${index === 0 || regeltage.innerText === '' ? '' : ', '}${tage[index]}`;
            }
        });
        // Verfügbare Wunschtage anzeigen
        const verfuegbar = htmlParent.querySelector('[aria-label="ma-Verfuegbarkeit"]');
        verfuegbar.innerText = '';
        aktuellerMA.Uebersicht.Verfuegbarkeit.forEach((tag, index) => {
            if (tag) {
                verfuegbar.innerText += `${index === 0 || verfuegbar.innerText === '' ? '' : ', '}${tage[index]}`;
            }
        });
        // Beschäftigungsart anzeigen
        const beschArten = await basedataService.holeBeschaeftigungsartenBasedataProvider();
        const beschaeftigungsart = htmlParent.querySelector('[aria-label="ma-Beschaeftigungsart"]');
        const tmpBA = beschArten.find((ba) => ba.BeschID === aktuelleBeschaeftigung.Beschaeftigungsart)?.BeschaeftigungsartKurz;
        beschaeftigungsart.innerText = !tmpBA ? '' : tmpBA;
        // User Login Status anzeigen
        updateArchivStatusButtons(aktuellerMA?.Archiviert);
        // Sofortmeldung anzeigen
        ladeSofortmeldung(aktuellerMA);
        // Mitarbeiter Zeiterfassung Aktivierungscode
        await ladeAktivierungsCode(aktuellerMA._id);

        // Mitarbeiter Benutzerinfo anzeigen
        await updateLoginStatusInfo(aktuellerMA);
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

/**
 * Click auf den Benutzerverwaltungsbutton leitet den User zur Benutzerverwaltung
 */
function clickZurBenutzerverwaltung() {
    window.myHandlers.topmenuClick(document.getElementById('Verwaltung'));
    window.myHandlers.submenuClick(document.getElementById('Benutzer'));
}

/**
 * Lädt/Aktualisiert die Statusanzeige der Sofortmeldung des aktuellen Mitarbeiters
 * @param {object} aktuellerMA
 */
function ladeSofortmeldung(aktuellerMA) {
    const sectionHTML = document.getElementById('ma-sofortmeldung-section');
    const sofortmeldungMA = aktuellerMA.Beschaeftigung[0].Sofortmeldung;
    const keineSofortmeldung = _.isEmpty(sofortmeldungMA?.Results);
    // Anzeigen des Sofortmelde Buttons nur falls es noch keine Sofortmeldung gab oder ein Storno vorliegt.
    sectionHTML.querySelector('.verwenden-button').style.display = _.isEmpty(sofortmeldungMA?.TAN) || sofortmeldungMA?.Status === 'storniert' ? 'block' : 'none';
    // Anzeige des Stornieren Buttons nur falls eine Stornierbare Sofortmeldung vorliegt.
    sectionHTML.querySelector('.entfernen-button').style.display = sofortmeldungMA?.Stornierbar ? 'block' : 'none';
    // reset info
    [...sectionHTML.querySelectorAll('.sofortmeldung-details')].forEach((item) => item.remove());
    if (!keineSofortmeldung) {
        const sofortmeldungTemplate = sectionHTML.querySelector('[ma-sofortmeldung-template]');
        const sofortmeldungDetails = sofortmeldungTemplate.content.cloneNode(true);
        displayValues(sofortmeldungMA, '', sofortmeldungDetails, 'ma-Sofortmeldung-');
        sectionHTML.appendChild(sofortmeldungDetails);
        if (sofortmeldungMA?.Status === 'storniert') {
            // Stornierungsfelder sichtbar machen
            [...sectionHTML.querySelectorAll('.ma-sm-storniert')].forEach((item) => { item.style.display = 'grid'; });
        } else {
            [...sectionHTML.querySelectorAll('.ma-sm-storniert')].forEach((item) => { item.style.display = 'none'; });
        }
    }
}

/**
 * Lädt den Benutzer Login Status
 * @param {*} aktuellerMA 
 */
async function updateLoginStatusInfo(aktuellerMA) {
     // Mitarbeiter Benutzerinfo anzeigen
     const userRollenHtml = document.querySelector('#ma-user-rollen');
     userRollenHtml.style.display = 'none';    
     const benutzer = await stammdatenService.ladeInternerMitarbeiterUser(aktuellerMA._id);
     if (!_.isEmpty(benutzer)) {
         const benutzerRollen = benutzer.neolohnRoles.filter((rolle) => rolle !== Roles.Mitarbeiter);
         if (benutzerRollen.length >= 1) {
             document.querySelector('[aria-label="ma-UserRollen"').innerText = benutzerRollen.join(',\n');
             userRollenHtml.style.display = 'grid';
         }
         if (benutzer.enabled) {
             updateLoginStatusButtons(true, true);
         } else {
             updateLoginStatusButtons(true, false);    
         }
     } else {
         updateLoginStatusButtons(false, false);
     }
}

function updateLoginStatusButtons(exists, loginStatus) {
    // ingorieren falls Seite verlassen
    if (window.myVars.websiteStatus !== 'ma-uebersicht') return;
    document.querySelector('[aria-label="ma-LoginStatus"]').innerText = loginStatus ? 'aktiv' : 'inaktiv';
    document.querySelector('[aria-label="ma-login-reaktivieren"]').style.display = loginStatus ? 'none' : 'block';
    document.querySelector('[aria-label="ma-login-reaktivieren"]').innerText = exists ? 'reaktivieren' : 'aktivieren';
    document.querySelector('[aria-label="ma-login-deaktivieren"]').style.display = loginStatus ? 'block' : 'none';
}

function updateArchivStatusButtons(archivStatus) {
    document.querySelector('[aria-label="ma-archiv-reaktivieren"]').style.display = archivStatus ? 'block' : 'none';
    document.querySelector('[aria-label="ma-archiv-archivieren"]').style.display = !archivStatus ? 'block' : 'none';
}

async function neueMitarbeiterNachricht(thisElement) {
    const parentElement = thisElement.closest('.fieldset3columns');
    const nachricht = parentElement.querySelector('#ma-nachricht-text');
    if (nachricht.value === '') {
        systemNachrichtService.zeigeKleineNachricht('kein Nachrichttext angebeben!');
        return;
    }
    // server request...
    const mitarbeiterID = stammdatenService.aktuellerMitarbeiter._id;
    const nachrichtObjekt = await stammdatenService.postMitarbeiterNachricht(mitarbeiterID, nachricht.value);
    if (!nachrichtObjekt) {
        return;
    }
    const maControl = document.getElementById(mitarbeiterID);
    // Zeige das Brief Symbol beim Mitarbeiter an
    maControl.querySelector('.ma-nachricht-icon').classList.remove('hidden');
    const template = document.querySelector('[ma-nachricht-template]');
    const liste = document.getElementById('ma-nachrichten-liste');
    const neueNachricht = template.content.cloneNode(true).children[0];
    neueNachricht.id = nachrichtObjekt._id;
    neueNachricht.querySelector('[aria-label="ma-Nachrichten-Nachricht"]').value = nachrichtObjekt.Nachricht;
    neueNachricht.querySelector('[aria-label="ma-Nachrichten-ErstelltVon"]').innerText = nachrichtObjekt.ErstelltVon;
    neueNachricht.querySelector('[aria-label="ma-Nachrichten-ErstelltDatum"]').innerText = dayjs(nachrichtObjekt.ErstelltDatum).format('DD.MM.YY');
    liste.prepend(neueNachricht);
    nachricht.value = '';
}

/**
 * Speichert die aktualisierte Mitarbeiter Nachricht
 * @param {HTMLElement} thisElement
 */
async function speichereMitarbeiterNachricht(thisElement) {
    const parentElement = thisElement.closest('.fieldset3columns');
    const nachrichtId = parentElement.id;
    const nachrichtNeu = parentElement.querySelector('[aria-label="ma-Nachrichten-Nachricht"]').value;
    const erledigt = parentElement.querySelector('[aria-label="ma-Nachrichten-Erledigt"]').checked;
    const mitarbeiterID = stammdatenService.aktuellerMitarbeiter._id;
    const nachrichtObjekt = await stammdatenService.updateMitarbeiterNachricht(mitarbeiterID, nachrichtId, nachrichtNeu, erledigt);
    parentElement.querySelector('[aria-label="ma-Nachrichten-BearbeitetVon"]').innerText = nachrichtObjekt.BearbeitetVon;
    parentElement.querySelector('[aria-label="ma-Nachrichten-BearbeitetDatum"]').innerText = dayjs(nachrichtObjekt.BearbeitetDatum).format('DD.MM.YY');
    parentElement.querySelector('.ma-notizen-bearbeitet-info').style.display = 'block';
    // Zeige das Brief Symbol beim Mitarbeiter an falls auf unerledigt gesetzt und ausblenden wenn erledigt
    const maControl = document.getElementById(mitarbeiterID);
    const briefSymbolMA = maControl.querySelector('.ma-nachricht-icon');
    if (!stammdatenService.aktuellerMitarbeiter.Uebersicht.NeueNachricht) {
        briefSymbolMA.classList.add('hidden');
        return;
    }
    briefSymbolMA.classList.remove('hidden');
}

/**
 * Lädt die Nachrichten des Mitarbeiters in die Oberfläche
 * @param {array} nachrichten
 */
function ladeMitarbeiterNachrichten(nachrichten) {
    const template = document.querySelector('[ma-nachricht-template]');
    const liste = document.getElementById('ma-nachrichten-liste');
    liste.innerHTML = '';
    nachrichten.sort((a, b) => new Date(b.BearbeitetDatum || b.ErstelltDatum) - new Date(a.BearbeitetDatum || a.ErstelltDatum));
    nachrichten.sort((a, b) => a.Erledigt - b.Erledigt);
    nachrichten.forEach((nachricht) => {
        const neueNachricht = template.content.cloneNode(true).children[0];
        neueNachricht.id = nachricht._id;
        displayValues(nachricht, '', neueNachricht, 'ma-Nachrichten-');
        if (nachricht.BearbeitetVon) {
            neueNachricht.querySelector('.ma-notizen-bearbeitet-info').style.display = 'block';
        }
        liste.appendChild(neueNachricht);
    });
}

// fetcht den Aktivierungscode des mitarbeiters falls vorhanden
async function ladeAktivierungsCode(mitarbeiterID) {
    try {
        const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/aktivierungscode`);
        const span = document.getElementById('ma-activation-code-number');
        const btn = document.getElementById('ma-activation-code-button');
        // Reset der Info
        span.innerText = '';
        span.style.display = 'none';
        btn.style.display = 'none';
        if (!response.ok) {
            const message = await response.text();
            systemNachrichtService.zeigeKleineNachricht(message, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        const result = await response.text();
        if (result !== '') {
            span.innerText = result;
            span.style.display = 'block';
        } else {
            btn.style = 'block';
        }
    } catch (error) {
        console.log(error);
    }
}

// fetch anfrage für die Erstellung eines Aktivierungscode für die Zeiterfassung
async function generiereAktivierungsCode() {
    try {
        const mitarbeiterID = stammdatenService.aktuellerMitarbeiter._id;
        const response = await fetch(`/neolohn/api/mitarbeiter/${mitarbeiterID}/aktivierungscode`, {
            method: 'POST',
        });
        if (response.ok) {
            // Reset der Info
            const span = document.getElementById('ma-activation-code-number');
            const btn = document.getElementById('ma-activation-code-button');
            span.innerText = '';
            span.style.display = 'none';
            btn.style.display = 'none';
            span.innerText = await response.text();
            span.style.display = 'block';
            systemNachrichtService.zeigeKleineNachricht('Erstellen des Aktivierungscodes erfolgreich!', 1);
        }
    } catch (error) {
        systemNachrichtService.zeigeKleineNachricht('Erstellen des Aktivierungscodes fehlgeschlagen!', -1);
    }
}

/**
 * Click Handler für die Erstellung einer Mitarbeiter Sofortmeldung
 */
async function beantrageSofortmeldung() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBetriebsstaette = stammdatenService.unternehmensobjekt.Betriebsstaette.find((bs) => bs._id === stammdatenService.aktuelleBetriebsstaette);
    if (_.isEmpty(aktuelleBetriebsstaette)) {
        return;
    }
    const dialogHtml = document.getElementById('ma-sofortmeldung-beantragen-dialog');
    const dialogBody = dialogHtml.querySelector('.app-dialog-body');
    dialogBody.innerHTML = '';
    const templateBody = dialogHtml.querySelector('[ma-sm-body-template]');
    const clone = templateBody.content.cloneNode(true);
    displayValues(aktuellerMA, '', clone, 'ma-sm-dialog-');
    displayValues(aktuellerMA.Personalien.Staatsangehoerigkeit, '', clone, 'ma-sm-dialog-Staatsangehoerigkeit-');
    displayValues(aktuellerMA.Personalien.Geburtsland, '', clone, 'ma-sm-dialog-Geburtsland-');
    displayValues(aktuelleBetriebsstaette, '', clone, 'bs-sm-dialog-');
    dialogBody.appendChild(clone);
    const input = await SystemDialogService.instance.displayAsync('ma-sofortmeldung-beantragen-dialog');
    if (input.success) {
        const result = await stammdatenService.postSofortmeldungMitarbeiter(aktuellerMA._id, aktuelleBetriebsstaette._id);
        if (result?.status === 'success') {
            ladeSofortmeldung(stammdatenService.aktuellerMitarbeiter);
        } else {
            // Fehler anzeigen
            const body = document.getElementById('ma-sm-error-body');
            const boldText = document.createElement('b');
            boldText.innerText = `Mitarbeiter: ${aktuellerMA.Personalien.Nachname}, ${aktuellerMA.Personalien.Vorname}`;
            body.appendChild(boldText);
            result.forEach((error) => {
                if (error.keyword === 'if') {
                    return;
                }
                const newRow = document.createElement('p');
                newRow.innerText = `${error.message}`;
                body.appendChild(newRow);
            });
            await SystemDialogService.instance.displayAsync('ma-sofortmeldung-error-dialog');
            body.innerText = '';
            ladeSofortmeldung(stammdatenService.aktuellerMitarbeiter);
        }
    }
    dialogBody.innerHTML = '';
}

/**
 * Click Handler für die Stornierung einer Mitarbeiter Sofortmeldung
 */
async function storniereSofortmeldung() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const dialogHtml = document.getElementById('ma-sofortmeldung-stornieren-dialog');
    const dialogName = dialogHtml.querySelector('#ma-sm-storno-name');
    dialogName.innerText = `${aktuellerMA.Personalien.Vorname} ${aktuellerMA.Personalien.Nachname}`;
    const input = await SystemDialogService.instance.displayAsync('ma-sofortmeldung-stornieren-dialog');
    if (input.success) {
        // führe storno aus
        await stammdatenService.postStornierungSofortmeldungMitarbeiter(aktuellerMA._id);
        ladeSofortmeldung(stammdatenService.aktuellerMitarbeiter);
    }
    dialogName.innerText = '';
}

// onclick handler für das Reaktivieren eines Mitarbeiter Logins
async function reaktiviereMitarbeiterLoginClick() {
    const input = await SystemDialogService.instance.displayAsync('ma-aktivieren-dialog');
    if (!input.success) {
        return;
    }
    const result = await stammdatenService.reaktivereMitarbeiterLogin(stammdatenService.aktuellerMitarbeiter._id);
    if (!result) {
        return;
    }
    await updateLoginStatusInfo(stammdatenService.aktuellerMitarbeiter);
}

// onclick handler für das Deaktivieren eines Mitarbeiter Logins
async function deaktiviereMitarbeiterLoginClick() {
    const input = await SystemDialogService.instance.displayAsync('ma-deaktivieren-dialog');
    if (!input.success) {
        return;
    }
    const result = await stammdatenService.deaktivereMitarbeiterLogin(stammdatenService.aktuellerMitarbeiter._id);
    if (!result) {
        return;
    }
    await updateLoginStatusInfo(stammdatenService.aktuellerMitarbeiter);
}

// onclick handler für das Archivieren eines Mitarbeiters
async function archiviereMitarbeiterClick() {
    const input = await SystemDialogService.instance.displayAsync('ma-archivieren-dialog');
    if (!input.success) {
        return;
    }
    const result = await stammdatenService.archiviereMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
    if (!result) {
        return;
    }
    await ladeUebersicht(stammdatenService.aktuellerMitarbeiter, stammdatenService.unternehmensobjekt);
    erstelleMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette);
}

// onclick handler für das Reaktivieren eines Mitarbeiters
async function reaktiviereMitarbeiterClick() {
    const result = await stammdatenService.reaktivereMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
    if (!result) {
        return;
    }
    await ladeUebersicht(stammdatenService.aktuellerMitarbeiter, stammdatenService.unternehmensobjekt);
    erstelleMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette);
}

/**
 * Event Handler für Klick auf Mitarbeiter Kopieren Button in der ma_uebersicht.html
 */
async function wechsleBeschaeftigungMA() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    // Dialog bestücken...
    const dialogHtml = document.getElementById('ma-beschaeftigung-wechsel-dialog');
    dialogHtml.querySelector('#mb-wechsel-austritt').value = aktuellerMA.Beschaeftigung[0].Austrittsdatum;
    dialogHtml.querySelector('#mb-wechsel-personalnummer').value = aktuellerMA.Personalien.Personalnummer;
    const selectBeschaeftigung = dialogHtml.querySelector('#mb-wechsel-beschaeftigungsart');
    const beschaeftigungen = await basedataService.holeBeschaeftigungsartenBasedataProvider();
    fuelleSelectOptionen(selectBeschaeftigung, beschaeftigungen, 'BeschID', ['BeschaeftigungsartKurz', 'Personengruppenschluessel']);
    selectBeschaeftigung.value = aktuellerMA.Beschaeftigung[0].Beschaeftigungsart;
    const input = await SystemDialogService.instance.displayAsync('ma-beschaeftigung-wechsel-dialog');
    if (!input.success) {
        return;
    }
    await stammdatenService.kopiereMitarbeiterBeschaeftigungswechsel(aktuellerMA._id, input.data);
    // liste und mitarbeiter neu laden...
    await stammdatenService.holeMitarbeiter(aktuellerMA._id);
    ladeUebersicht(stammdatenService.aktuellerMitarbeiter, stammdatenService.unternehmensobjekt);
	await erstelleMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette);
}

// Dialog confirm handler
function confirmWechselBeschaeftigungMA() {
    const dialogHtml = document.getElementById('ma-beschaeftigung-wechsel-dialog');
    const austritt = dialogHtml.querySelector('#mb-wechsel-austritt').value;
    const eintritt = dialogHtml.querySelector('#mb-wechsel-eintritt').value;
    // nicht ohne richte Datumsangaben abschicken lassen
    if (dayjs(austritt).isAfter(eintritt) || austritt === '' || eintritt === '') {
        systemNachrichtService.zeigeKleineNachricht('Eintrittsdatum muss nach dem Austrittsdatum liegen!');
        return;
    }
    SystemDialogService.instance.confirm({
		AustrittsdatumAlt: austritt,
		EintrittsdatumNeu: eintritt,
		BeschaeftigungsartNeu: dialogHtml.querySelector('#mb-wechsel-beschaeftigungsart').value,
		PersonalnummerNeu: dialogHtml.querySelector('#mb-wechsel-personalnummer').value
	});
}


/**
 * Speichert das aktuelle Mitarbeiternotizenfeld
 * @param {*} thisElement Notiz textarea
 */
function speichereMitarbeiterNotiz(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    aktuellerMA.Uebersicht.Notizen = thisElement.value;
    window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

export { ladeUebersicht };