/**
 * Hier kommt Code für das Zeitkonto bzw. EGFZ Geschichten hin
 * - Gutzeitkonto
 * - Urlaubskonto
 * - Mutterschutz
 * - Krank
 */

import _ from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';
import { validateZeitkonto } from './ma_daten.js';
import stammdatenService from '../services/stammdatenService.js';
import {
    checkUrlaubsantragKollisionen,
    displayArray,
    displayValues,
    erstelleMitarbeiterListe,
    formatNumber2Decimals
} from '../util.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import schichtService from '../services/schichtService.js';

window.myHandlers = window.myHandlers || {};
window.myVars = window.myVars || {};
window.myHandlers.speichereUrlaubskonto = speichereUrlaubskonto;
window.myHandlers.updateUrlaubsantrag = updateUrlaubsantrag;
window.myHandlers.updateUrlaubZusatz = updateUrlaubZusatz;
window.myHandlers.updateUrlaubVorwert = updateUrlaubVorwert;
window.myHandlers.updateGutzeitVorwert = updateGutzeitVorwert;
window.myHandlers.updateGutzeitAbgegolten = updateGutzeitAbgegolten;
window.myHandlers.erstelleEntbindungstermin = erstelleEntbindungstermin;
window.myHandlers.confirmEntbindungsdialog = confirmEntbindungsdialog;
window.myHandlers.entferneEntbindungstermin = entferneEntbindungstermin;

async function ladeZeitkonto(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        const journalKomplett = await stammdatenService.leseJournalHistorieMitarbeiter(aktuellerMA._id);
        // Lade Urlaubsdaten
        ladeUrlaubskonto(aktuellerMA);
        // Lade Gutzeitkonto
        ladeGutzeitkonto(aktuellerMA, journalKomplett);
        // Lade Kranktage Konto
        ladeKranktagekonto(journalKomplett);
        // Lade urzarbeit Konto
        ladeKurzarbeitKonto(journalKomplett);
        // Lade Mutterschutz
        ladeMutterschutz(aktuellerMA);
    }
}

/**
 * Ladet die Urlaubswerte und -anträge in die Oberfläche
 * @param {object} aktuellerMA
 */
function ladeUrlaubskonto(aktuellerMA) {
    const zeitkontoHTML = document.getElementById('ma-Zeitkonto');
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    displayValues(aktuelleBeschaeftigung.Urlaub, '', zeitkontoHTML, 'ma-');
    validateZeitkonto(aktuellerMA);
    const urlaubsantraege = aktuelleBeschaeftigung.Urlaubsantraege;
    const antragHTML = document.getElementById('ma-Urlaubsantrag');
    displayArray(urlaubsantraege, 'ma-ua-', antragHTML);
}

/**
 * Ladet alle Journaldaten des Jahres in denen die Gutzeiten vermerkt sind
 */
async function ladeGutzeitkonto(aktuellerMA, journalKomplett) {
    const vorwertInput = document.querySelector('[aria-label="ma-gz-GutzeitVorwert"]');
    const vorwertValue = aktuellerMA.Beschaeftigung[0]?.GutzeitVorwert ? Math.round(aktuellerMA.Beschaeftigung[0].GutzeitVorwert * 100) / 100 : 0;
    vorwertInput.value = vorwertValue;
    if (window.myVars.HideMod) {
        vorwertInput.disabled = true;
    } else {
        vorwertInput.disabled = false;
    }
    // Wir wollen durch jeden Monat iterieren...
    const jahresAnfang = window.myVars.aktuellesMonatsDatum.startOf('year');
    const journalJahr = journalKomplett.filter((journal) => dayjs(journal.ZeitraumDate).isSame(jahresAnfang, 'year'));
    const gutzeitMonateDiv = document.getElementById('ma-gz-monate');
    gutzeitMonateDiv.innerHTML = '';
    const gutzeitTemplate = document.querySelector('[ma-gz-template]');
    for (let i = 0; i < 12; i++) {
        // Monat
        const tmpDate = jahresAnfang.add(i, 'months');
        const journalMonat = journalJahr.find((journal) => dayjs(journal.ZeitraumDate).isSame(tmpDate, 'month'));
        if (journalMonat) {
            const zeitraum = tmpDate.format('YYYY/MM');
            const gutzeitMonatHTML = gutzeitTemplate.content.cloneNode(true).children[0];
            gutzeitMonatHTML.id = journalMonat._id;
            gutzeitMonatHTML.setAttribute('aria-label', tmpDate.format('YYYY-MM-DD'));
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitMonat"]').innerText = zeitraum;
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitMinus"]').value = formatNumber2Decimals(journalMonat.Mitarbeiter.Monatswerte.GutzeitMinus);
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitPlus"]').value = formatNumber2Decimals(journalMonat.Mitarbeiter.Monatswerte.GutzeitPlus);
            const gutzeitMonatAbgegolten = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].GutzeitAbgegolten.find((gz) => gz.Zeitraum === tmpDate.format('YYYY-MM-DD'));
            const abgegoltenValue = gutzeitMonatAbgegolten ? gutzeitMonatAbgegolten.AbgegoltenStunden : 0;
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitAbgegolten"]').value = Math.round(abgegoltenValue * 100) / 100;
            // wir dürfen das Feld nur öffnen, wenn das Journal nicht abgeschlossen ist, der Monat übereinstimmt mit dem aktuellen Zeitraum und es nur eine aktive Qualifikation gibt!
            const verschiedeneQualis = new Set(aktuellerMA.Beschaeftigung[0].Verguetung.filter((vg) => vg.Verwenden).map((qu) => qu.QualifikationID)).size;
            if (!journalMonat.Abgeschlossen
                    && window.myVars.aktuellesMonatsDatum.isSame(journalMonat.Zeitraum, 'month')
                    && verschiedeneQualis === 1
                    && !window.myVars.HideMod) {
                gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitAbgegolten"]').disabled = false;
            }
            const gzMonat = -journalMonat.Mitarbeiter.Monatswerte.GutzeitMinus + journalMonat.Mitarbeiter.Monatswerte.GutzeitPlus - abgegoltenValue;
            gutzeitMonatHTML.querySelector('[aria-label="ma-gz-GutzeitRest"]').value = formatNumber2Decimals(gzMonat);
            gutzeitMonateDiv.appendChild(gutzeitMonatHTML);
        }
    }
    calcGutzeitSummen(aktuellerMA);
}

function calcGutzeitSummen(aktuellerMA) {
    // Plus
    const gutzeitAufbau = Array.from(document.querySelectorAll('[aria-label="ma-gz-GutzeitPlus"]'));
    const aufbauSumme = gutzeitAufbau.reduce((sum, gz) => sum + parseFloat(gz.value.replace(',', '.')), 0);
    document.querySelector('[aria-label="ma-gz-GutzeitSummePlus"]').innerText = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(aufbauSumme);
    // Minus
    const gutzeitAbbau = Array.from(document.querySelectorAll('[aria-label="ma-gz-GutzeitMinus"]'));
    const abbauSumme = gutzeitAbbau.reduce((sum, gz) => sum + parseFloat(gz.value.replace(',', '.')), 0);
    document.querySelector('[aria-label="ma-gz-GutzeitSummeMinus"]').innerText = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(abbauSumme);
    // Abgegolten
    const gutzeitAbgegolten = Array.from(document.querySelectorAll('[aria-label="ma-gz-GutzeitAbgegolten"]'));
    const abgSumme = gutzeitAbgegolten.reduce((sum, gz) => sum + parseFloat(gz.value), 0);
    document.querySelector('[aria-label="ma-gz-GutzeitSummeAbgegolten"]').innerText = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(abgSumme);
    // Reste
    const gutzeitenReste = Array.from(document.querySelectorAll('[aria-label="ma-gz-GutzeitRest"]'));
    const resteSumme = gutzeitenReste.reduce((sum, gz) => sum + parseFloat(gz.value.replace(',', '.')), 0);
    const vorwert = aktuellerMA.Beschaeftigung[0].GutzeitVorwert;
    document.querySelector('[aria-label="ma-gz-GutzeitSummeRest"]').innerText = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(resteSumme + vorwert);
}

async function updateGutzeitAbgegolten(thisElement, event) {
    const urlaubsMonatHTML = thisElement.parentNode;
    const minus = parseFloat(urlaubsMonatHTML.querySelector('[aria-label="ma-gz-GutzeitMinus"]').innerText.replace(',', '.'));
    const plus = parseFloat(urlaubsMonatHTML.querySelector('[aria-label="ma-gz-GutzeitPlus"]').innerText.replace(',', '.'));
    // wir können maximal die Überstunden abgelten lassen...
    let abgeltenValue = parseFloat(thisElement.value);
    if (Number.isNaN(abgeltenValue) || abgeltenValue < 0) {
        systemNachrichtService.zeigeKleineNachricht('Ungültige Eingabe, bitte Wert > 0!', 0);
        if (thisElement.value.includes('-')) {
            thisElement.value = thisElement.value.replace('-', '');
        }
        return;
    }
    const rest = plus - minus - abgeltenValue;
    urlaubsMonatHTML.querySelector('[aria-label="ma-gz-GutzeitRest"]').innerText = rest;
    calcGutzeitSummen(stammdatenService.aktuellerMitarbeiter);
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const gutzeitAbgeltung = aktuellerMA.Beschaeftigung[0].GutzeitAbgegolten;
    const zeitraum = thisElement.parentNode.getAttribute('aria-label');
    const abgeltungsmonat = gutzeitAbgeltung.find((gz) => gz.Zeitraum === zeitraum);
    if (abgeltungsmonat) {
        abgeltungsmonat.AbgegoltenStunden = abgeltenValue;
    } else {
        gutzeitAbgeltung.push({ Zeitraum: zeitraum, AbgegoltenStunden: abgeltenValue });
    }
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
}

async function updateGutzeitVorwert(thisElement) {
    // Um eine einfache Minuseingabe zu ermöglichen, müssen wir ein Löschen des Inputs erlauben
    if (thisElement.value === '-' || thisElement.value === '') {
        return;
    }
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    let vorwert =  parseFloat(thisElement.value);
    if (Number.isNaN(vorwert)) {
        systemNachrichtService.zeigeKleineNachricht('Gutzeit Vorwert automatisch auf 0 gesetzt!', 0);
        vorwert = 0;
        thisElement.value = 0;
    }
    aktuellerMA.Beschaeftigung[0].GutzeitVorwert = Math.round(vorwert * 100) / 100;
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    calcGutzeitSummen(aktuellerMA);
}

/**
 * Berechnet die Urlaubsansprüche und Summen neu und aktualisiert diese.
 */
async function speichereUrlaubskonto() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    // Die Summe des Urlaubsanspruch sowie die verbleibenden Urlaubstage werden von Zusatz- oder Vorwertänderungen betroffen und müssen angepasst werden...
    aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchSumme = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchZusatzLaufendesJahr + aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchLaufendesJahr + aktuelleBeschaeftigung.Urlaub.ResturlaubVorwert;
    aktuelleBeschaeftigung.Urlaub.ResturlaubLaufendesJahr = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchSumme - aktuelleBeschaeftigung.Urlaub.UrlaubGenehmigtSumme;
    aktuellerMA.Uebersicht.Resturlaub = aktuelleBeschaeftigung.Urlaub.ResturlaubLaufendesJahr;
    const urlaubHtml = document.getElementById('ma-Urlaubskonto');
    urlaubHtml.querySelector('[aria-label="ma-UrlaubsanspruchSumme"').value = aktuelleBeschaeftigung.Urlaub.UrlaubsanspruchSumme;
    urlaubHtml.querySelector('[aria-label="ma-ResturlaubLaufendesJahr"').value = aktuelleBeschaeftigung.Urlaub.ResturlaubLaufendesJahr;
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    validateZeitkonto(aktuellerMA);
    // neue Daten anzeigen...
    displayValues(stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Urlaub, '', urlaubHtml, 'ma-');
}

/**
 * Updated den UrlaubsanspruchZusatzProJahr und -LaufendesJahr.
 * @param {HTMLElement} thisElement input feld des Urlaub Zusatz pro Jahr
 */
async function updateUrlaubZusatz(thisElement) {
    let urlaubszusatzJahr = parseInt(thisElement.value, 10);
    if (Number.isNaN(urlaubszusatzJahr) || urlaubszusatzJahr < 0) {
        systemNachrichtService.zeigeKleineNachricht('Urlaubsanspruchszusatz automatisch auf 0 gesetzt!', 0);
        thisElement.value = 0;
        urlaubszusatzJahr = 0;
    }
    document.body.querySelector('[aria-label="ma-UrlaubsanspruchZusatzProJahr"]').value = urlaubszusatzJahr;
    stammdatenService.aktuellerMA.Beschaeftigung[0].Urlaub.UrlaubsanspruchZusatzProJahr = urlaubszusatzJahr;
    await speichereUrlaubskonto();
}

/**
 * Updated die Resturlaub Vorwerte
 * @param {HTMLElement} thisElement input feld des Urlaub Vorwerts.
 */
async function updateUrlaubVorwert(thisElement) {
    // Um eine einfache Minuseingabe zu ermöglichen, müssen wir ein Löschen des Inputs erlauben
    if (thisElement.value === '-' || thisElement.value === '') {
        return;
    }
    let resturlaubVorjahr = parseInt(thisElement.value, 10);
    if (Number.isNaN(resturlaubVorjahr)) {
        thisElement.value = 0;
        resturlaubVorjahr = 0;
        systemNachrichtService.zeigeKleineNachricht('Resturlaub automatisch auf 0 gesetzt!', 0);
    }
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const urlaubVerbleibend = document.querySelector('[aria-label="ma-ResturlaubLaufendesJahr"]');
    const differenz = aktuelleBeschaeftigung.Urlaub.ResturlaubVorwert - resturlaubVorjahr;
    const result = parseInt(urlaubVerbleibend.value, 10) - differenz;
    urlaubVerbleibend.value = result;
    aktuelleBeschaeftigung.Urlaub.ResturlaubVorwert = resturlaubVorjahr;
    await speichereUrlaubskonto();
}

/**
 * Updated den Urlaubsantragsstatus, je nach dem welche Aktion ausgeführt wurde
 * @param {HTMLElement} thisElement Urlaub antrag akzeptieren/ablehnen/reset button
 * @param {string} statusUpdate abgelehnt, offen, genehmigt
 */
async function updateUrlaubsantrag(thisElement, statusUpdate) {
    const urlaubsantragHTML = document.getElementById(`ma-ua-${thisElement.id}`);
    const urlaubStatus = urlaubsantragHTML.querySelector('[aria-label="ma-ua-UrlaubStatus"]');
    if (urlaubStatus.innerText === statusUpdate) {
        systemNachrichtService.zeigeKleineNachricht(`Der Status des Urlaubantrags ist bereits ${statusUpdate}!`);
        return;
    }
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    // Wir suchen den Urlaubsantrag im Mitarbeiter
    const urlaubsantrag = aktuellerMA.Beschaeftigung[0].Urlaubsantraege.find((ua) => ua._id === thisElement.id);
    if (!urlaubsantrag) {
        systemNachrichtService.zeigeKleineNachricht('Urlaubsantrag nicht gefunden!', -1);
        return;
    }
    // Hier müssten vor dem Speichervorgang die Urlaubstage als Schichten ein oder ausgetragen werden...
    if (urlaubsantrag.UrlaubStatus !== 'genehmigt' && statusUpdate === 'genehmigt') {
        const kollisionsIndex = checkUrlaubsantragKollisionen(aktuellerMA, dayjs(urlaubsantrag.UrlaubVon), dayjs(urlaubsantrag.UrlaubBis), urlaubsantrag._id);
        if (kollisionsIndex >= 0) {
            await SystemDialogService.instance.displayAsync('zeiten-urlaub-kollision-dialog');
            return;
        }
        await schichtService.erstelleUrlaubAusAntrag(aktuellerMA._id, urlaubsantrag._id, statusUpdate);
        await ladeZeitkonto(stammdatenService.aktuellerMitarbeiter);
        return;
    }
    // Wenn wir einen genehmigten Urlaubseintrag widerrufen (offen oder ablehnen), müssen wir die Urlaubstage austragen.
    if (urlaubsantrag.UrlaubStatus === 'genehmigt' && statusUpdate !== 'genehmigt') {
        await schichtService.entferneUrlaub(aktuellerMA._id, urlaubsantrag._id, statusUpdate);
        await ladeZeitkonto(stammdatenService.aktuellerMitarbeiter);
        return;
    }
    // aktualisiere und speichere urlaubsantragstatus
    urlaubStatus.innerText = statusUpdate;
    urlaubsantrag.UrlaubStatus = statusUpdate;
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    await erstelleMitarbeiterListe();
}

/**
 * Ladet alle Journaldaten des Jahres in denen die Kranktage vermerkt sind
 */
async function ladeKranktagekonto(journalHistorie) {
    // Wir wollen durch jeden Monat iterieren...
    const jahresAnfang = window.myVars.aktuellesMonatsDatum.startOf('year');
    const journalJahr = journalHistorie.filter((journal) => dayjs(journal.ZeitraumDate).isSame(jahresAnfang, 'year'));
    const kranktageMonateDiv = document.getElementById('ma-kt-monate');
    kranktageMonateDiv.innerHTML = '';
    const kranktageTemplate = document.querySelector('[ma-kt-template]');
    for (let i = 0; i < 12; i++) {
        // Monat
        const tmpDate = jahresAnfang.add(i, 'months');
        const journalMonat = journalJahr.find((journal) => dayjs(journal.ZeitraumDate).isSame(tmpDate, 'month'));
        if (journalMonat) {
            const zeitraum = tmpDate.format('YYYY/MM');
            const krankTageMonatHTML = kranktageTemplate.content.cloneNode(true).children[0];
            krankTageMonatHTML.id = journalMonat._id;
            krankTageMonatHTML.querySelector('[aria-label="ma-kt-KranktageMonat"]').innerText = zeitraum;
            displayValues(journalMonat.Mitarbeiter.Monatswerte, '', krankTageMonatHTML, 'ma-kt-');
            kranktageMonateDiv.appendChild(krankTageMonatHTML);
        }
    }
    calcKranktageSummen();
}

function calcKranktageSummen() {
    // Kranktage
    const kranktage = Array.from(document.querySelectorAll('[aria-label="ma-kt-Kranktage"]'));
    const kranktageSum = kranktage.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-KranktageSumme"]').innerText = kranktageSum;
    // KindKrank
    const kindKrank = Array.from(document.querySelectorAll('[aria-label="ma-kt-KindKrank"]'));
    const kindKrankSum = kindKrank.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-KindKrankSumme"]').innerText = kindKrankSum;
    // Krank6Wochen
    const krank6Wochen = Array.from(document.querySelectorAll('[aria-label="ma-kt-Krank6Wochen"]'));
    const krank6WochenSum = krank6Wochen.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-Krank6WochenSumme"]').innerText = krank6WochenSum;
    // UnentschuldigtKrank
    const unentschuldigtKrank = Array.from(document.querySelectorAll('[aria-label="ma-kt-UnentschuldigtKrank"]'));
    const unentschuldigtKrankSum = unentschuldigtKrank.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-UnentschuldigtKrankSumme"]').innerText = unentschuldigtKrankSum;
    // KrankErste4Wochen (KO)
    const krankErste4Wochen = Array.from(document.querySelectorAll('[aria-label="ma-kt-KO"]'));
    const krankErste4WochenSum = krankErste4Wochen.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-KOSumme"]').innerText = krankErste4WochenSum;
    // KranktageAlle
    const kranktageAlle = Array.from(document.querySelectorAll('[aria-label="ma-kt-KranktageAlle"]'));
    const kranktageAlleSum = kranktageAlle.reduce((sum, gz) => sum + parseFloat(gz.innerText), 0);
    document.querySelector('[aria-label="ma-kt-KranktageAlleSumme"]').innerText = kranktageAlleSum;
}

/**
 * Ladet alle Journaldaten des Jahres in denen die Kranktage vermerkt sind
 */
async function ladeKurzarbeitKonto(journalHistorie) {
    // Wir wollen durch jeden Monat iterieren...
    const jahresAnfang = window.myVars.aktuellesMonatsDatum.startOf('year');
    // Nur kurzfristige Beschäftigung berücksichtigen
    const journalJahr = journalHistorie.filter((journal) => dayjs(journal.ZeitraumDate).isSame(jahresAnfang, 'year') && journal.Mitarbeiter.Beschaeftigung[0].Personengruppenschluessel === '110');
    const kurzarbeitMonateDiv = document.getElementById('ma-kat-monate');
    kurzarbeitMonateDiv.innerHTML = '';
    const kranktageTemplate = document.querySelector('[ma-kat-template]');
    let katSumme = 0;
    for (let i = 0; i < 12; i++) {
        // Monat
        const tmpDate = jahresAnfang.add(i, 'months');
        const journalMonat = journalJahr.find((journal) => dayjs(journal.ZeitraumDate).isSame(tmpDate, 'month'));
        if (journalMonat) {
            const zeitraum = tmpDate.format('YYYY/MM');
            const krankTageMonatHTML = kranktageTemplate.content.cloneNode(true).children[0];
            krankTageMonatHTML.id = journalMonat._id;
            krankTageMonatHTML.querySelector('[aria-label="ma-kat-KranktageMonat"]').innerText = zeitraum;
            krankTageMonatHTML.querySelector('[aria-label="ma-kat-Kranktage"]').innerText = journalMonat.Mitarbeiter.Monatswerte.Arbeitstage;
            katSumme += journalMonat.Mitarbeiter.Monatswerte.Arbeitstage;
            kurzarbeitMonateDiv.appendChild(krankTageMonatHTML);
        }
    }
    document.querySelector('[aria-label="ma-kat-KranktageSumme"]').innerText = katSumme;
}

async function erstelleEntbindungstermin() {
    const result = await SystemDialogService.instance.displayAsync('entbindungstermin-dialog');
    if (result.success) {
        console.log(result.data);
        await stammdatenService.erstelleEntbindungsterminMA(stammdatenService.aktuellerMitarbeiter._id, result.data);
        await ladeZeitkonto(stammdatenService.aktuellerMitarbeiter);
    }
}

function confirmEntbindungsdialog() {
    if (!document.getElementById('ma-entbindung-datum').value) {
        systemNachrichtService.zeigeKleineNachricht('Entbindungstermin muss angegeben werden!');
        return;
    }
    SystemDialogService.instance.confirm({
        Entbindungsdatum: document.getElementById('ma-entbindung-datum').value,
        EntbindungBesondere: document.getElementById('ma-entbindung-besondere').checked,
        EntbindungBerufsverbot: document.getElementById('ma-entbindung-berufsverbot').checked,
        EntbindungBerufsverbotVon: document.getElementById('ma-entbindung-berufsverbot-von').value,
    });
}

function ladeMutterschutz(aktuellerMA) {
    const templateMS = document.body.querySelector('[ma-mutterschutz-template]');
    const listMS = document.getElementById('ma-mutterschutz-list');
    listMS.innerHTML = '';
    if (!_.isEmpty(aktuellerMA.Beschaeftigung[0].Mutterschutz)) {
        aktuellerMA.Beschaeftigung[0].Mutterschutz.forEach((ms) => {
            const newItem = templateMS.content.cloneNode(true).children[0];
            newItem.id = ms._id;
            displayValues(ms, '', newItem, 'ma-Mutterschutz-');
            listMS.appendChild(newItem);
        });
    }
}

async function entferneEntbindungstermin(thisElement) {
    await SystemDialogService.instance.displayAsync('entbindungstermin-delete-dialog');
    const terminID = thisElement.closest('.ma-mutterschutz-item').id;
    await stammdatenService.entferneEntbindungsterminMA(stammdatenService.aktuellerMitarbeiter._id, terminID);
    ladeMutterschutz(stammdatenService.aktuellerMitarbeiter);
}

export {
    ladeZeitkonto
};
